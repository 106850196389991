const CountryPhoneCode = [
  {
    code: '+93',
  },
  {
    code: '+355',
  },
  {
    code: '+213',
  },
  {
    code: '+1-684',
  },
  {
    code: '+376',
  },
  {
    code: '+244',
  },
  {
    code: '+1-264',
  },
  {
    code: '+672',
  },
  {
    code: '+1-268',
  },
  {
    code: '+54',
  },
  {
    code: '+374',
  },
  {
    code: '+297',
  },
  {
    code: '+61',
  },
  {
    code: '+43',
  },
  {
    code: '+994',
  },
  {
    code: '+1-242',
  },
  {
    code: '+973',
  },
  {
    code: '+880',
  },
  {
    code: '+1-246',
  },
  {
    code: '+375',
  },
  {
    code: '+32',
  },
  {
    code: '+501',
  },
  {
    code: '+229',
  },
  {
    code: '+1-441',
  },
  {
    code: '+975',
  },
  {
    code: '+591',
  },
  {
    code: '+387',
  },
  {
    code: '+267',
  },
  {
    code: '+55',
  },
  {
    code: '+246',
  },
  {
    code: '+1-284',
  },
  {
    code: '+673',
  },
  {
    code: '+359',
  },
  {
    code: '+226',
  },
  {
    code: '+257',
  },
  {
    code: '+855',
  },
  {
    code: '+237',
  },
  {
    code: '+1',
  },
  {
    code: '+238',
  },
  {
    code: '+1-345',
  },
  {
    code: '+236',
  },
  {
    code: '+235',
  },
  {
    code: '+56',
  },
  {
    code: '+86',
  },
  {
    code: '+57',
  },
  {
    code: '+269',
  },
  {
    code: '+682',
  },
  {
    code: '+506',
  },
  {
    code: '+385',
  },
  {
    code: '+53',
  },
  {
    code: '+599',
  },
  {
    code: '+357',
  },
  {
    code: '+420',
  },
  {
    code: '+243',
  },
  {
    code: '+45',
  },
  {
    code: '+253',
  },
  {
    code: '+1-767',
  },
  {
    code: '+1-809, 1-829, 1-849',
  },
  {
    code: '+670',
  },
  {
    code: '+593',
  },
  {
    code: '+20',
  },
  {
    code: '+503',
  },
  {
    code: '+240',
  },
  {
    code: '+291',
  },
  {
    code: '+372',
  },
  {
    code: '+251',
  },
  {
    code: '+500',
  },
  {
    code: '+298',
  },
  {
    code: '+679',
  },
  {
    code: '+358',
  },
  {
    code: '+33',
  },
  {
    code: '+689',
  },
  {
    code: '+241',
  },
  {
    code: '+220',
  },
  {
    code: '+995',
  },
  {
    code: '+49',
  },
  {
    code: '+233',
  },
  {
    code: '+350',
  },
  {
    code: '+30',
  },
  {
    code: '+299',
  },
  {
    code: '+1-473',
  },
  {
    code: '+1-671',
  },
  {
    code: '+502',
  },
  {
    code: '+44-1481',
  },
  {
    code: '+224',
  },
  {
    code: '+245',
  },
  {
    code: '+592',
  },
  {
    code: '+509',
  },
  {
    code: '+504',
  },
  {
    code: '+852',
  },
  {
    code: '+36',
  },
  {
    code: '+354',
  },
  {
    code: '+91',
  },
  {
    code: '+62',
  },
  {
    code: '+98',
  },
  {
    code: '+964',
  },
  {
    code: '+353',
  },
  {
    code: '+44-1624',
  },
  {
    code: '+972',
  },
  {
    code: '+39',
  },
  {
    code: '+225',
  },
  {
    code: '+1-876',
  },
  {
    code: '+81',
  },
  {
    code: '+44-1534',
  },
  {
    code: '+962',
  },
  {
    code: '+7',
  },
  {
    code: '+254',
  },
  {
    code: '+686',
  },
  {
    code: '+383',
  },
  {
    code: '+965',
  },
  {
    code: '+996',
  },
  {
    code: '+856',
  },
  {
    code: '+371',
  },
  {
    code: '+961',
  },
  {
    code: '+266',
  },
  {
    code: '+231',
  },
  {
    code: '+218',
  },
  {
    code: '+423',
  },
  {
    code: '+370',
  },
  {
    code: '+352',
  },
  {
    code: '+853',
  },
  {
    code: '+389',
  },
  {
    code: '+261',
  },
  {
    code: '+265',
  },
  {
    code: '+60',
  },
  {
    code: '+960',
  },
  {
    code: '+223',
  },
  {
    code: '+356',
  },
  {
    code: '+692',
  },
  {
    code: '+222',
  },
  {
    code: '+230',
  },
  {
    code: '+262',
  },
  {
    code: '+52',
  },
  {
    code: '+691',
  },
  {
    code: '+373',
  },
  {
    code: '+377',
  },
  {
    code: '+976',
  },
  {
    code: '+382',
  },
  {
    code: '+1-664',
  },
  {
    code: '+212',
  },
  {
    code: '+258',
  },
  {
    code: '+95',
  },
  {
    code: '+264',
  },
  {
    code: '+674',
  },
  {
    code: '+977',
  },
  {
    code: '+31',
  },
  {
    code: '+687',
  },
  {
    code: '+64',
  },
  {
    code: '+505',
  },
  {
    code: '+227',
  },
  {
    code: '+234',
  },
  {
    code: '+683',
  },
  {
    code: '+850',
  },
  {
    code: '+1-670',
  },
  {
    code: '+47',
  },
  {
    code: '+968',
  },
  {
    code: '+92',
  },
  {
    code: '+680',
  },
  {
    code: '+970',
  },
  {
    code: '+507',
  },
  {
    code: '+675',
  },
  {
    code: '+595',
  },
  {
    code: '+51',
  },
  {
    code: '+63',
  },
  {
    code: '+48',
  },
  {
    code: '+351',
  },
  {
    code: '+1-787, 1-939',
  },
  {
    code: '+974',
  },
  {
    code: '+242',
  },
  {
    code: '+40',
  },
  {
    code: '+250',
  },
  {
    code: '+590',
  },
  {
    code: '+290',
  },
  {
    code: '+1-869',
  },
  {
    code: '+1-758',
  },
  {
    code: '+508',
  },
  {
    code: '+1-784',
  },
  {
    code: '+685',
  },
  {
    code: '+378',
  },
  {
    code: '+239',
  },
  {
    code: '+966',
  },
  {
    code: '+221',
  },
  {
    code: '+381',
  },
  {
    code: '+248',
  },
  {
    code: '+232',
  },
  {
    code: '+65',
  },
  {
    code: '+1-721',
  },
  {
    code: '+421',
  },
  {
    code: '+386',
  },
  {
    code: '+677',
  },
  {
    code: '+252',
  },
  {
    code: '+27',
  },
  {
    code: '+82',
  },
  {
    code: '+211',
  },
  {
    code: '+34',
  },
  {
    code: '+94',
  },
  {
    code: '+249',
  },
  {
    code: '+597',
  },
  {
    code: '+268',
  },
  {
    code: '+46',
  },
  {
    code: '+41',
  },
  {
    code: '+963',
  },
  {
    code: '+886',
  },
  {
    code: '+992',
  },
  {
    code: '+255',
  },
  {
    code: '+66',
  },
  {
    code: '+228',
  },
  {
    code: '+690',
  },
  {
    code: '+676',
  },
  {
    code: '+1-868',
  },
  {
    code: '+216',
  },
  {
    code: '+90',
  },
  {
    code: '+993',
  },
  {
    code: '+1-649',
  },
  {
    code: '+688',
  },
  {
    code: '+1-340',
  },
  {
    code: '+256',
  },
  {
    code: '+380',
  },
  {
    code: '+971',
  },
  {
    code: '+44',
  },
  {
    code: '+598',
  },
  {
    code: '+998',
  },
  {
    code: '+678',
  },
  {
    code: '+379',
  },
  {
    code: '+58',
  },
  {
    code: '+84',
  },
  {
    code: '+681',
  },
  {
    code: '+967',
  },
  {
    code: '+260',
  },
  {
    code: '+263',
  },
];

export default CountryPhoneCode;
