import { styled } from 'styles';
import color from 'styles/color';
import shadow from 'styles/shadow';
import typography from 'styles/typography';

import PhoneCodeSelectInput from '../phone-code-select-input';

export const InputStyled = styled('input', {
  padding: 0,
  border: 'none',
  display: 'flex',
  width: '100%',
  outline: 'none',
  minHeight: 44,
  borderRadius: 6,
  px: 16,
  ...typography.body2,
});

export const InputContainer = styled('div', {
  display: 'flex',
  alignItems: 'center',
  flex: 1,
  py: 2,
  borderRadius: 6,
  borderWidth: 1,
  borderStyle: 'solid',
  backgroundColor: color.white,
  '& > *:not(:first-child)': {
    marginRight: 16,
  },
  variants: {
    variant: {
      focus: {
        ...shadow.textInputFocus,
        borderColor: color.info,
      },
      error: {
        ...shadow.textInputError,
        borderColor: color.error,
      },
      success: {
        ...shadow.textInputSuccess,
        borderColor: color.success,
      },
      disabled: {
        borderColor: color.neutral2,
        backgroundColor: color.neutral1,
      },
      default: {
        borderColor: color.neutral2,
      },
    },
  },
});

export const Container = styled('div', {
  display: 'flex',
  flexDirection: 'column',
});

export const FieldContainer = styled('div', {
  display: 'flex',
  flex: 1,
});

export const Hint = styled('span', {
  ...typography.body4,
  color: color.info,
  display: 'flex',
  alignItems: 'center',
  marginTop: 4,
  svg: {
    marginRight: 8,
  },
  variants: {
    variant: {
      focus: {},
      error: {},
      success: {
        color: color.success,
      },
      disabled: {},
      default: {
        color: color.info,
      },
    },
  },
});

export const Label = styled('label', {
  ...typography.body3,
  color: color.typeDisabled,
  marginBottom: 4,
  variants: {
    required: {
      true: {
        '&::after': {
          content: '*',
          marginLeft: 8,
          color: color.error,
          fontSize: 20,
        },
      },
    },
  },
});

export const ErrorMessage = styled('span', {
  ...typography.body3,
  marginTop: 4,
  color: color.error,
  svg: {
    marginRight: 8,
  },
});

export const PhoneCode = styled(PhoneCodeSelectInput, {
  marginRight: 4,
  width: '100%',
  maxWidth: 100,
});

export const IconButton = styled('button', {
  cursor: 'pointer',
  borderRadius: 4,
  '&:disabled': {
    cursor: 'not-allowed',
  },
  '&:focus': {
    outlineColor: '#DCD4FF',
    outlineStyle: 'solid',
    outlineWidth: 'medium',
  },
});
