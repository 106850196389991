import * as React from 'react';
import * as Icons from 'react-feather';
import color from 'styles/color';

import {
  Container,
  ErrorMessage,
  FieldContainer,
  Hint,
  IconButton,
  InputContainer,
  InputStyled,
  Label,
  PhoneCode,
} from './styles';
import { IPhoneCodeProps } from '../phone-code-select-input';

export interface ITextFieldProps
  extends React.InputHTMLAttributes<HTMLInputElement> {
  label?: string;
  hint?: string;
  errorMessage?: string;
  onRenderLeftComponent?: React.ReactNode;
  onRenderRightComponent?: React.ReactNode;
  phoneCodeProps?: IPhoneCodeProps;
  hideXIcon?: boolean;
  hideEyeIcon?: boolean;
  onXClick?: () => void;
}

const TextField = React.forwardRef<HTMLInputElement, ITextFieldProps>(
  (props, ref) => {
    const [showPassword, setShowPassword] = React.useState(false);
    const fallbackRef = React.useRef<HTMLInputElement>(null);
    const domRef = ref || fallbackRef;
    const {
      label,
      hint,
      errorMessage,
      type,
      onRenderLeftComponent,
      onRenderRightComponent,
      phoneCodeProps,
      hideXIcon = false,
      hideEyeIcon = false,
      onXClick,
      ...rest
    } = props;
    const [state, setState] = React.useState<
      'default' | 'error' | 'focus' | 'success' | 'disabled'
    >('default');

    React.useEffect(() => {
      if (errorMessage) {
        setState('error');
      } else {
        setState('default');
      }
    }, [errorMessage]);

    React.useEffect(() => {
      if (rest.disabled) {
        setState('disabled');
      } else {
        setState('default');
      }
    }, [rest.disabled]);

    return (
      <Container>
        {label && <Label required={rest.required}>{label}</Label>}
        <FieldContainer>
          {type === 'phone' && (
            <PhoneCode {...phoneCodeProps} clearable={false} />
          )}
          <InputContainer variant={state}>
            {onRenderLeftComponent}
            <InputStyled
              ref={domRef}
              {...rest}
              disabled={rest.disabled}
              onFocus={(e) => {
                rest.onFocus && rest.onFocus(e);
                setState('focus');
              }}
              onBlur={(e) => {
                rest.onBlur && rest.onBlur(e);
                if (e.target.value.length > 0) {
                  if (errorMessage) {
                    setState('error');
                  } else {
                    setState('success');
                  }
                } else {
                  setState('default');
                }
              }}
              type={showPassword ? 'text' : type}
            />
            {!!rest.value && !hideXIcon && !rest.disabled && !rest.readOnly && (
              <IconButton
                type="button"
                css={{
                  color: '$disabled',
                  opacity: 0.5,
                  '&:hover': {
                    opacity: 1,
                  },
                }}
                onClick={() => {
                  if (fallbackRef.current) {
                    fallbackRef.current.value = '';
                    onXClick && onXClick();
                    setState('default');
                  }
                  onXClick && onXClick();
                  setState('default');
                }}
              >
                <Icons.X size={16} strokeWidth={3.5} />
              </IconButton>
            )}
            {!hideEyeIcon &&
              (!rest.disabled || !rest.readOnly) &&
              type === 'password' && (
                <IconButton
                  type="button"
                  css={{
                    color: '$disabled',
                    opacity: 0.5,
                    '&:hover': { opacity: 1 },
                  }}
                  onClick={() => setShowPassword((prev) => !prev)}
                >
                  {showPassword ? (
                    <Icons.Eye size={16} strokeWidth={3.5} />
                  ) : (
                    <Icons.EyeOff size={16} strokeWidth={3.5} />
                  )}
                </IconButton>
              )}
            {onRenderRightComponent}
          </InputContainer>
        </FieldContainer>
        {errorMessage && (
          <ErrorMessage>
            <Icons.AlertTriangle color={color.error} size={16} />
            {errorMessage}
          </ErrorMessage>
        )}
        {hint && (
          <Hint variant={state}>
            {state === 'success' ? (
              <Icons.CheckCircle color={color.success} size={16} />
            ) : (
              <Icons.Info color={color.info} size={16} />
            )}
            {hint}
          </Hint>
        )}
      </Container>
    );
  },
);

TextField.displayName = 'TextField';

export default TextField;
