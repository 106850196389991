import CountryPhoneCode from 'common/helpers/country-phone-code';
import * as React from 'react';

import Select, { GroupBase, OptionsOrGroups, ISelectProps } from './select';

export interface IPhoneCodeProps extends Omit<ISelectProps, 'options'> {}

export default function PhoneCodeSelectInput(props: ISelectProps) {
  const options = React.useMemo<
    OptionsOrGroups<unknown, GroupBase<unknown>> | undefined
  >(
    () =>
      CountryPhoneCode.map((item) => ({ value: item.code, label: item.code })),
    [],
  );
  return <Select options={options} {...props} uniqueId="phone-code" />;
}
