const shadow = {
  textInputFocus: {
    boxShadow: '0px 2px 2px rgba(21, 76, 152, 0.12)',
  },
  textInputSuccess: {
    boxShadow: '0px 2px 2px rgba(144, 255, 91, 0.12)',
  },
  textInputError: {
    boxShadow: '0px 2px 2px rgba(245, 83, 112, 0.12)',
  },
};

export default shadow;
