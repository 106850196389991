import { styled } from 'styles';

export const StepperItem = styled('div', {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  width: '100%',
  variants: {
    variant: {
      active: {
        backgroundColor: '$info',
        color: '$white',
      },
      normal: {
        backgroundColor: '$white',
        '&:hover': {
          backgroundColor: '$infoBg',
        },
      },
    },
  },
});

export const Div = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  padding: 8,
});

export const Divider = styled('div', {
  height: 1,
  width: '100%',
  backgroundColor: '$dbGray1',
});
