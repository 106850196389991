import Text from 'components/commons/text';
import * as React from 'react';
import RawSelect, {
  components,
  ActionMeta,
  GroupBase,
  OptionsOrGroups,
  StylesConfig,
  ControlProps,
} from 'react-select';
import { FormatOptionLabelMeta } from 'react-select/dist/declarations/src/Select';
import color from 'styles/color';
import shadow from 'styles/shadow';
import typography from 'styles/typography';

import { Div } from './dropdown/style';
export type { GroupBase, OptionsOrGroups } from 'react-select';

const customStyle: StylesConfig<any> = {
  input: (styles) => ({
    ...styles,
    ...typography.body2,
  }),
  indicatorSeparator: (styles) => ({ display: 'none' }),
  dropdownIndicator: (styles) => ({ display: 'none' }),
  placeholder: (styles) => ({
    ...styles,
    ...typography.body2,
    width: '100%',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  }),
  // @ts-ignore
  control: (styles, { isFocused, isDisabled, hasValue, isError }) => ({
    ...styles,
    borderRadius: 6,
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: color.neutral2,
    height: 50,
    ...(isFocused && {
      ...shadow.textInputFocus,
      borderColor: color.info,
    }),
    ...(isDisabled && {
      borderColor: color.neutral2,
      backgroundColor: color.neutral1,
    }),
    ...(!isDisabled &&
      hasValue && {
        ...shadow.textInputSuccess,
        borderColor: color.success,
      }),
    ...(!isDisabled &&
      isError && {
        ...shadow.textInputError,
        borderColor: color.error,
      }),
  }),
  option: (styles) => {
    return {
      ...styles,
      ...typography.body2,
    };
  },
};

interface IControlProps extends ControlProps<any, boolean, GroupBase<any>> {}

export interface ISelectProps {
  options?: OptionsOrGroups<unknown, GroupBase<unknown>>;
  disabled?: boolean;
  searchable?: boolean;
  onBlur?: React.FocusEventHandler<HTMLInputElement>;
  onChange?: (newValue: any, actionMeta: ActionMeta<any>) => void;
  clearable?: boolean;
  name?: string;
  value?: any;
  isLoading?: boolean;
  onRenderLeft?: React.ReactNode;
  uniqueId?: string;
  placeholder?: string;
  isError?: boolean;
  noDataMessage?: string;
  formatOptionLabel?: (
    data: unknown,
    formatOptionLabelMeta: FormatOptionLabelMeta<unknown>,
  ) => React.ReactNode;
}

const Control = (props: IControlProps) => {
  const { children, ...rest } = props;
  // @ts-ignore
  const { onRenderLeft, isError } = rest.selectProps;
  return (
    <components.Control
      {...rest}
      //@ts-ignore
      isError={isError}
    >
      {onRenderLeft}
      {children}
    </components.Control>
  );
};

const SingleValue = (props) => {
  const { label } = props.getValue()[0];
  return (
    <components.SingleValue {...props}>
      <Text variant="body2">{label}</Text>
    </components.SingleValue>
  );
};

const Input = ({ ...rest }) => (
  // @ts-ignore
  <components.Input {...rest} autoComplete="nope" />
);

const Select = React.forwardRef<any, ISelectProps>((props, ref) => {
  const {
    disabled,
    searchable,
    clearable = true,
    onRenderLeft,
    uniqueId = '',
    noDataMessage = 'no options',
    ...rest
  } = props;

  return (
    <RawSelect
      ref={ref}
      // @ts-ignore
      onRenderLeft={onRenderLeft}
      inpu
      components={{ Control, Input, SingleValue }}
      isClearable={clearable}
      styles={customStyle}
      isDisabled={disabled}
      isSearchable={searchable}
      instanceId={uniqueId}
      noOptionsMessage={() => <Div>{noDataMessage}</Div>}
      {...rest}
    />
  );
});

export default Select;
